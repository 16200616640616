@import './others/selec2-b4';

// Customize & Overwrite Css
.app-footer {
    height: 47px;
}

.app-content {
    &--inner {
        padding: 2rem 0.7rem 1rem;
    }
}

.app-page-title {
    margin: -2rem -2rem 0.6rem;
    padding: 0.5rem 2rem;
    background-color: #fff;
}

.app-page-title--heading h1 {
    font-size: 16px;
}

.sidebar-navigation .sidebar-header {
    padding: 0.5rem;
}

.sidebar-navigation ul li.submenu-open ul li a {
    text-overflow: ellipsis;
    white-space: nowrap;
    display: block;
}

.text-dark {
    color: #000 !important;
}

.font-weight-light {
    font-weight: 600 !important;
    font-size: 13px;
    color: #0c1159;
}

.svg-inline--fa {
    vertical-align: middle;
}

.sign-in-container {
    .h1-title {
        font-size: 1.2rem;
    }

    .p-subtitle {
        font-size: 0.92rem;
    }
}

.btn-sm-icon {
    padding: 1px 8px !important;
}

.btn-outline-slack {
    color: #3aaf85;
    border-color: #3aaf85;
}

.btn-outline-slack:not(:disabled):hover {
    box-shadow: 0 0.22rem 0.525rem rgba(58, 175, 133, 0.4), 0 0.0625rem 0.385rem rgba(58, 175, 133, 0.54);
}

.btn-outline-slack:hover {
    color: #fff;
    background-color: #3aaf85;
    border-color: #3aaf85;
}

.overlay {
    background-color: rgba(0, 0, 0, 0.35);
    bottom: 0;
    content: '';
    display: block;
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    transition: all .3s ease;
    z-index: 999998;
    opacity: 0;
    visibility: hidden;
}

.overlay.active {
    opacity: 1;
    transform: translateX(0);
    /*transition: all .3s ease .3s;*/
    visibility: visible;
}

.ajaxloader img {
    background: #fff;
    position: fixed;
    top: 0;
    left: 0;
    border-radius: 7px;
    padding: 15px;
    border: 5px solid #095dbd;
    width: 150px;
    height: 130px;
    bottom: 0;
    right: 0;
    margin: auto;
}

.ajaxloader .page-loader {
    background: #fff;
    position: fixed;
    top: 0;
    left: 0px;
    border-radius: 7px;
    padding: 15px;
    /* border: 5px solid #095dbd; */
    width: 220px;
    height: 80px;
    bottom: 0;
    right: 0;
    margin: auto;
}

//  Core
.page-drawer-content {
    position: fixed;
    right: 0;
    top: 75px;
    bottom: 0;
    z-index: 1001;
    height: 100vh;
    transform: translate(450px);
    transition: all 0.5s cubic-bezier(0.685, 0.0473, 0.346, 1);
    box-shadow: 0 0 0 0 transparent;
    width: 450px;
    background: $white;

    &.bg-light-blue {
        background-color: #f4f5fd;
    }

    &.xs {
        width: 450px;
        transform: translate(450px);
    }

    &.sm {
        width: 576px;
        transform: translate(576px);
    }

    &.md {
        width: 768px;
        transform: translate(768px);
    }

    &.lg {
        width: 992px;
        transform: translate(992px);
    }

    &.xl {
        width: 1200px;
        transform: translate(1200px);
    }

    .close-page-drawer-btn {
        transition: $transition-base;
        position: absolute;
        left: -19px;
        width: 38px;
        height: 38px;
        margin: 0;
        top: 19px;
        padding: 0;
        border: 0;
        background: $white;
        color: $white;
        box-shadow: $box-shadow-sm;
        z-index: 1155;
        opacity: 0;
        visibility: hidden;
    }
}

.page-drawer-wrapper {
    border-left: rgba($black, .1) solid 1px;
    padding: ($spacer / 3.5) 0 ($spacer / 3.5) ($spacer * 2);
    margin: 0 0 0 ($spacer * 2);
    height: 50px;
    display: flex;
    align-items: center;

    @include media-breakpoint-down(lg) {
        padding: ($spacer / 3.5) 0 ($spacer / 3.5) ($spacer);
        margin: 0 0 0 $spacer;
    }
}

// Open header drawer section

.page-drawer-open {

    .page-drawer-content {
        transform: translate(0);
        // box-shadow: $header-drawer-open-box-shadow;
    }

    .close-page-drawer-btn {
        opacity: 1;
        visibility: visible;
        @include border-radius(100%);
    }
}

// Drawer overlay

.page-drawer-overlay {
    width: 0;
    height: 0;
    opacity: 0;
    visibility: hidden;
    transition: opacity .2s;
    position: fixed;
    left: 0;
    top: 75px;
    right: 0;
    bottom: 0;
    background: rgba(7, 9, 25, .4);
    background: linear-gradient(90deg, rgba(7, 9, 25, .1) 25%, rgba(7, 9, 25, 0.6) 100%);
    z-index: 1000;

    &.is-active {
        opacity: 1;
        visibility: visible;
        width: 100%;
        height: 100%;

        &.bgClickable {
            opacity: 0;
            visibility: hidden;
        }
    }
}

@include media-breakpoint-down(md) {

    .page-drawer-wrapper,
    .page-drawer-overlay,
    .page-drawer-content {
        display: none;
    }
}



/***** Form Control Customize ****/
.form-control-sm {
    height: 35px;
    border-radius: 2px;
    padding: 0.25rem 0.9rem;

    &.invalid {
        border-color: red;
    }

    &::placeholder {
        color: #8d8989;
    }
}

.form-group {
    label {
        font-size: 0.82rem;
    }

    .custom-control {
        .custom-control-label {
            line-height: 1.9;
        }
    }
}

.input-group.subscription-period-group {
    .input-group-text {
        padding: 0.4rem 1.3rem;
        font-size: 0.82rem;
        border-left: 0;
    }
}

.custom-checkbox .custom-control-label::before {
    border-radius: 2px;
}

.date-range {
    .react-datepicker-wrapper {
        width: 44%;

        &.w-100 {
            width: 100%;
        }
    }

    &.xs {
        .react-datepicker-wrapper {
            width: 39%;
        }
    }
}

.date-picker {
    .date-picker-label {
        padding: 0 10px !important;
        color: #001f3f;
        margin-bottom: 0;
    }

    .react-datepicker-wrapper {
        .react-datepicker__input-container {
            .date-range-picker {
                width: 220px !important;
                height: 34px;
                padding: 10px;
                border-color: #7a7b974d !important;
                border: 1px solid;
                font-size: 14px;
            }

            .date-range-picker:focus-visible {
                border-color: #3c44b1 !important;
                outline: none !important;
            }
        }
    }
}

.date-picker-col {
    margin-left: 20px;
    margin-bottom: 0;
}

@media (max-width: 1440px) {


    .date-picker {
        .date-picker-label {
            padding: 0 10px !important;
            color: #001f3f;
            margin-bottom: 0;
        }

        .react-datepicker-wrapper {
            .react-datepicker__input-container {
                .date-range-picker {
                    width: 150px !important;
                    height: 34px;
                    padding: 10px;
                    border-color: #7a7b974d !important;
                    border: 1px solid;
                    font-size: 14px;
                }

                .date-range-picker:focus-visible {
                    border-color: #3c44b1 !important;
                    outline: none !important;
                }
            }
        }
    }

}

.date-picker-query {
    .date-picker-label {
        padding: 0 10px !important;
        color: #001f3f;
        margin-bottom: 0;
    }

    .react-datepicker-wrapper {
        .react-datepicker__input-container {
            .date-range-picker {
                width: 200px !important;
                height: 37px;
                padding: 10px;
                border-color: #7a7b974d !important;
                border: 1px solid;
                font-size: .8rem;
                color: #3b3e66;
            }

            .date-range-picker:focus-visible {
                border-color: #3c44b1 !important;
                outline: none !important;
            }
        }
    }
}

@media (max-width: 768px) {
    .date-picker-col {
        margin-left: 0;
        margin-bottom: 10px;
    }
}

.react-auto-complete--menu {
    position: absolute;
    margin: 2px 0;
    padding: 0;
    background-color: #fff;
    width: 100%;
    border: 1px solid #d1d2db;
    border-radius: 2px;
    border-top: 0;
    font-size: 0.82rem;
    z-index: 105;
    max-height: 150px;
    overflow: hidden;
    overflow-y: auto;

    .react-auto-complete--options {
        padding: 5px 15px;

        &.highlighted {
            background-color: #3c44b1;
            color: #fff;
        }

        &:hover {
            background-color: #e0e2fc;
            color: #000;
        }
    }
}

.alert {
    border-radius: 2px;
    font-size: 0.82rem;
}

//Table Customize Style
.table {
    thead th {
        vertical-align: middle;
        font-size: 13px;
        font-weight: 600;
        padding: 0.45rem 0.75rem;
    }

    tbody td {
        vertical-align: middle;
        font-size: 13px;
        background-color: white;
        padding: 0.45rem 0.75rem;
        color: #000;

    }

    &.fz10 {
        th {
            font-size: 10px;
        }
    }
}



//Sweetalert Custom Style

.swal-modal {
    width: 400px;

    .swal-icon {
        margin: 10px auto;
    }

    .swal-icon:first-child {
        margin-top: 20px !important;
    }

    .swal-title {
        font-size: 18px;
    }
}

.validity-tooltip {
    max-width: 370px !important;
    width: 370px;
    padding: 0.55rem 1rem;
}

.ReactTable .rt-thead .rt-tr {
    font-size: 13px;
}

.ReactTable .rt-tbody .rt-tr {
    font-size: 12px;
}

.ReactTable .rt-tr {
    align-items: center;
}

/**** Border Radius ***/
.round-4px {
    border-radius: 4px;
}

/***** Cards List *****/
.project-list-overflow {
    height: calc(100vh - 195px) !important;
    position: relative;
}

.project-list-status {
    font-weight: normal;
    font-size: 13px;
}

.card-list-box {
    border-radius: 4px;
    margin-bottom: 1rem;

    .card-header {
        padding: 0.6rem 0.8rem;
        background-color: #fff;
        border-bottom: 1px solid #efefef;
        margin-bottom: 0;
        font-size: 14px;
        font-weight: 600;
    }

    .card-body {
        padding: 0.6rem 0.8rem;
        font-size: 12px;
    }

    .card-footer {
        padding: 0.6rem 0.8rem;
        background-color: #fff;
        border-top: 1px solid #efefef;
        margin-top: 0;
        font-size: 12px;
        font-weight: 400;
    }

    .view-link {
        color: var(--red);
    }
}

.rc-select-selection {
    border-radius: 2px;
}

.rc-select-dropdown {
    padding: 0;
    border-radius: 2px;
}

li.rc-select-dropdown-menu-item {
    padding: 5px 10px;
    border-radius: 2px;
    text-align: center;
}

.rc-pagination-total-text {
    font-weight: 400;
    text-transform: lowercase;
}

/***** For Finance Data *****/




.finance-nav-tabs.nav.nav-tabs .nav-item .nav-link {
    font-size: 12px;
}

.finance-data-tab-content table {
    thead th {
        background-color: unset;
        font-size: 13px;
        padding: 5px 10px;
        text-transform: capitalize;
    }

    th.list-heading {
        background-color: #9BC2E6;
    }

    tbody {
        th {
            padding: 5px 10px;
            font-size: 13px;
        }

        td {
            font-size: 12px;
        }
    }
}

.list-heading {
    background-color: #9BC2E6;
}

.list-sp-highlight {
    background-color: #FFFFCC;
}

.modalCloseBtn {
    z-index: 100;
    position: absolute;
    top: 5px;
    right: 10px;
}

.company-detail-card {
    font-size: 0.82rem;
}

.btn.btn-md {
    padding: 0.41rem 1.3rem;
}

.form-group>label {
    font-size: 0.82rem;
    color: #0c1159;
    font-weight: 600;
}

.av-radio-group,
.form-group {
    legend {
        font-size: 0.82rem;
        color: #161616;
    }
}

.selec2 {
    font-size: 0.82rem !important;
}

.rc-select {
    line-height: 28px;
    font-size: 0.83rem;

    &.is-invalid {

        .rc-select-selection,
        .rc-select-selection:hover {
            border-color: red;
        }
    }
}

.rc-select-selection--multiple {
    .rc-select-selection__placeholder {
        top: 3px;
    }
}

.rc-dropdown-styles {
    min-height: 30px;
    max-height: 200px;
    overflow: hidden;
    overflow-y: auto;
    text-align: left;
}

li.rc-select-dropdown-menu-item {
    text-align: left;
    font-size: 0.83rem;
}

//Mon-Year Calendar
.custom-month-year-calendar {
    &.react-datepicker {
        border: 0;
        border-radius: 0.19rem;
    }

    .react-datepicker__month-container {
        margin: 0;

        .react-datepicker__header {
            text-align: center;
            background-color: #3c44b1;
            border-top-left-radius: 0.19rem;
            border-top-right-radius: 0.19rem;
            color: #fffcfc;
            padding: 7px 0 5px;

            &.react-datepicker-year-header {
                font-size: 0.854rem;
            }
        }

        .react-datepicker__month {
            border: 0;

            .react-datepicker__month-text {
                padding: 5px 10px;
                border-radius: 0.15rem;
                font-size: 0.832rem;

                &.react-datepicker__month--selected {
                    background-color: #3c44b1;
                }
            }
        }
    }
}

.rdtPicker {
    box-shadow: 0 1px 7px rgba(31, 29, 29, 0.34) !important;

    th.rdtNext,
    th.rdtPrev {
        width: 35px;
    }

    td.rdtActive,
    td.rdtActive:hover {
        background-color: #3c44b1 !important;
    }

    td.rdtMonth,
    td.rdtYear {
        font-size: 0.832rem;
    }
}

.project-view-details-card {
    .card-body>.row>div {
        margin-bottom: 10px;
    }
}


.row.project-view-details-card>div {
    margin-bottom: 10px;
}

.input-group.subscription-period-group .input-group-text {
    padding: 0.4rem 0.8rem;
}

.rc-select-dropdown-menu-item-group-title {
    font-size: 14px;
    padding: 5px 10px;
}

@mixin cent-width($some-no) {
    width: $some-no;
}

.w-35 {
    @include cent-width(35%);
}

.w-40 {
    @include cent-width(40%);
}

.w-42 {
    @include cent-width(42%);
}

.w-43 {
    @include cent-width(43%);
}

.w-44 {
    @include cent-width(44%);
}

table.qry-res-table {

    th,
    td {
        white-space: nowrap;
    }
}

.checkbox-custom-qry-search {
    font-size: 0.83rem;
}

/******** Table Custom Style ********/
.table {
    .tr {
        transition: all 0.2s ease-in-out;
    }

    .thead {
        overflow-y: auto;
        overflow-x: hidden;

        .th {
            vertical-align: middle;
            font-weight: 600;
            padding: 0.45rem 0.75rem;
            text-transform: uppercase;
            background: #f4f5fd;
            font-size: 0.83125rem;
            border-bottom: 2px solid #dcdef1;
        }
    }

    &.table-bordered {
        .thead {

            .th,
            .td {
                border-bottom-width: 2px;
            }
        }

        .th,
        .td {
            border: 1px solid #dcdef1;
        }
    }

    .tbody {
        overflow-y: scroll;
        overflow-x: hidden;
        height: 250px;

        .td {
            vertical-align: middle;
            font-size: 12px;
            padding: 0.45rem 0.75rem;
        }
    }
}

.was-validated .form-control:invalid,
.form-control.is-invalid {
    border-color: #f83245 !important;
}

.table-style.custom {
    border-spacing: 0;
    border: 1px solid black;
    display: block;
    overflow: auto;

    .thead {
        /** These styles are required for a scrollable body to align with the header properly **/
        overflow-y: auto;
        overflow-x: hidden;
    }

    .tbody {
        /** These styles are required for a scrollable table body **/
        overflow-y: scroll;
        overflow-x: hidden;
        height: 250px;
    }

    .tr {
        :last-child {
            .td {
                border-bottom: 0;
            }
        }

        border-bottom: 1px solid black;
    }

    .th,
    .td {
        margin: 0;
        padding: 0.5rem;
        border-right: 1px solid black;

        /** In this example we use an absolutely position resizer,
       so this is required. ***/
        position: relative;

        :last-child {
            border-right: 0;
        }

        .resizer {
            right: 0;
            background: blue;
            width: 10px;
            height: 100%;
            position: absolute;
            top: 0;
            z-index: 1;
            /**prevents from scrolling while dragging on touch devices **/
            touch-action: none;

            &.isResizing {
                background: red;
            }
        }
    }
}

tr.selected-row {
    background-color: #fffbcc;
}

.qry-param-selected-label {
    display: inline-block;
    position: relative;
    padding: 3px 10px;
    border: 1px solid #eaeaea;
    margin-left: 8px;
    margin-right: 8px;

    small {
        font-size: 70%;

        &.param-key-label {
            font-weight: 600;
        }
    }

    /***span.btn {
        position: absolute;
		right: -8px;
		top: -6px;
		overflow: visible;
		padding: 0px 3px;
		line-height: 10px;
		border-radius: 0;
    }***/
}

.qry-label-btn {
    position: relative;
    padding: 0 25px 0 8px;
    margin-bottom: 0;
    //background: #746565;
    background: #c8ccff;
    color: #252525;
    margin-left: 3px;
    margin-right: 3px;

    &.without-btn {
        padding: 0 8px;
    }

    .label-txt {
        display: block;
        padding: 4px 2px;
    }

    span.btn.label-close-btn {
        position: absolute;
        right: 0;
        top: 0;
        padding: 1px 5px;
        line-height: 16px;
        overflow: visible;
        border-radius: 0;
    }
}

td.col-width {
    max-width: 300px;
    overflow: hidden;
    text-overflow: ellipsis;
    width: auto;
    min-width: 1px;
}

td.col-width.stretch_name {
    max-width: 600px;
}

.vertical-text {
    margin: 0 0 0 0;
    -webkit-writing-mode: vertical-lr;
    -ms-writing-mode: tb-lr;
    writing-mode: vertical-lr;
    text-orientation: upright;
    -webkit-font-feature-settings: "vkrn", "vpal";
    font-feature-settings: "vkrn", "vpal";
}

$color: #0cf;

.button-animated {
    display: inline-block;
    padding: .75rem 1.25rem;
    border-radius: 0;
    color: #fff;
    text-transform: uppercase;
    letter-spacing: .15rem;
    transition: all .3s;
    position: relative;
    overflow: hidden;
    z-index: 1;

    &:after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: $color;
        border-radius: 0;
        z-index: -2;
    }

    &:before {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 0%;
        height: 100%;
        background-color: darken($color, 15%);
        transition: all .3s;
        border-radius: 0;
        z-index: -1;
    }

    &:hover {
        color: #fff;

        &:before {
            width: 100%;
        }
    }
}

.show-additional-fields {
    text-transform: uppercase;
    transform: rotate(90deg);
    position: absolute;
    right: -70px;
    top: 200px;
    min-width: 169px;
    z-index: 10;

    &:hover {
        transform: rotate(90deg) !important;
    }
}

.show-additional-columns-container {
    position: absolute;
    z-index: 9;
    transform: translate(98.1%);
    top: 130px;
    width: 60%;
    right: 0px;
    transition: all 0.4s ease;

    &.open {
        transform: translate(0%);
        transition: all 0.4s ease;
        height: auto;
        right: 0px;
        opacity: 1;



    }

    .show-additional-columns-inner {
        text-transform: uppercase;
        transform: rotate(90deg);
        transform-origin: left top;
        position: absolute;
        /* height: 3vh; */
        left: 0;
        right: 0;
        top: 0;
        /* min-width: 389px; */
        z-index: 10;
        color: #fff;
        background-color: #3c44b1 !important;
        border-color: #3c44b1 !important;

        &:hover {
            transform: rotate(90deg) !important;
            color: #fff;
            background-color: #3c44b1 !important;
            border-color: #3c44b1 !important;
        }
    }
}

.box-shadow-none {
    box-shadow: none !important;
}

.cost-amt-fsize {
    font-size: 1rem;
    font-weight: 700;
    line-height: 1.2;
}

.cost-cr-fsize {
    font-size: 0.65em;
    font-weight: 700;
    margin-left: 2px;
}

.bg-navy {
    background-color: #001f3f !important;
}

.bg-teal {
    background-color: #39cccc !important;
}

.bg-olive {
    background-color: #3d9970 !important;
}

.bg-lime {
    background-color: #01ff70 !important;
}

.bg-orange {
    background-color: #ff851b !important;
}

.bg-fuchsia {
    background-color: #f012be !important;
}

.bg-purple {
    background-color: #605ca8 !important;
}

.bg-maroon {
    background-color: #d81b60 !important;
}

.bg-gray-active {
    color: #000;
    background-color: #b5bbc8 !important;
}

.bg-black-active {
    background-color: #000000 !important;
}

.underbidding-progress {
    .progress-bar {
        background-color: #944dff !important;
    }
}

.under-implement-progress {
    .progress-bar {
        background-color: #0099ff !important;
    }
}

.shelved-progress {
    .progress-bar {
        background-color: #ff6666 !important;
    }
}

.completed-progress {
    .progress-bar {
        background-color: #00cc66 !important;
    }
}

.provisionally-completed-progress {
    .progress-bar {
        background-color: #39ff14 !important;
    }
}

.force-closed-progress {
    .progress-bar {
        background-color: #228C22 !important;
    }
}

.terminated-progress {
    .progress-bar {
        background-color: #FF2800 !important;
    }
}

.eoi-stage-progress {
    .progress-bar {
        background-color: #FFFF00 !important;
    }
}




.position-unset {
    position: unset !important;
}

.progress.prelative {
    position: relative;

    .progress-bar {
        position: unset !important;

        .center {
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            color: #000;
        }
    }
}

.transform-none {
    transform: none !important;

    &:hover {
        transform: none !important;
    }
}

@mixin bgcolor-code($some-no) {
    background-color: $some-no !important;
}

.bgcolor-944dff {
    @include bgcolor-code(#944dff);
}

.bgcolor-0099ff {
    @include bgcolor-code(#0099ff);
}

.bgcolor-80a3ff {
    @include bgcolor-code(#80a3ff);
}

.bgcolor-ff6666 {
    @include bgcolor-code(#ff6666);
}

.bgcolor-00cc66 {
    @include bgcolor-code(#00cc66);
}

.bgcolor-228C22 {
    @include bgcolor-code(#228C22);
}

.bgcolor-FF2800 {
    @include bgcolor-code(#FF2800);
}

.bgcolor-1aadce {
    @include bgcolor-code(#1aadce);
}

.bgcolor-f28f43 {
    @include bgcolor-code(#f28f43);
}

.bgcolor-77a1e5 {
    @include bgcolor-code(#77a1e5);
}

.bgcolor-90ed7d {
    @include bgcolor-code(#90ed7d);
}

.show-finance-checkbox {
    font-size: 0.83rem;

    .custom-control-label {
        padding-top: 4px;
    }
}

.nav-logo a {
    font-size: 1.2rem;

    i {
        width: 32px;
        height: 28px;
        display: flex;
        align-items: center;
        border-radius: 0;
        margin: 0 0.2rem 0 0;
        position: relative;
        left: 0;

        img {
            animation: none;
        }
    }
}

.z-index-2100 {
    z-index: 2100 !important;
}


@media (min-width: 769px) and (max-width: 1360px),
(min-width: 769px) and (max-width: 360px) and (min-resolution: 192dpi) {

    .app-page-title {
        &--heading h1 {
            //font-size: 14px;
        }
    }

}

.filter-label-text {
    font-size: 0.82rem;
    margin-right: 5px;
}


.portal-version {
    font-size: 10px;
    font-weight: normal;
}

.bg-new-theme-color {
    background-color: #061420;

    .nav.nav-line .nav-item .nav-link {
        cursor: pointer;

        &.active {
            color: #fff;
        }

        &:hover {
            color: #fff;
        }

        .divider {
            background-color: #fff;
        }
    }

    .sign-in-container {
        .h1-title {
            color: #dedede;
        }

        .p-subtitle {
            color: #b9b6b6 !important;
        }

        .forgot-pwd-content a {
            color: #61C7C1;

            &:hover {
                color: #4191ff;
            }
        }

        button[type="submit"] {
            background: #61C7C1;
            border-color: #61C7C1;

            &:hover {
                //box-shadow: 0 0.22rem 0.525rem rgb(55 154 148 / 40%), 0 0.0625rem 0.385rem rgb(44 113 117 / 54%);
                box-shadow: 0 0.22rem 0.525rem rgba(55, 154, 148, 0.4), 0 0.0625rem 0.385rem rgba(44, 113, 117, 0.54);
            }
        }
    }

    .ip-address-subtitle {
        color: #fff;
    }
}

.open-popup {
    width: 230px;
    // min-height: 100px;
    height: auto;
    background-color: #3b3e66;
    position: absolute;
    bottom: 23px;
    left: 48px;
    border-radius: 4px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
}

.open-popup:before {
    content: '';
    position: absolute;
    top: 100%;
    left: 30%;
    border: 8px solid #3b3e66;
    border-left-color: transparent;
    border-right-color: transparent;
    border-bottom-color: transparent;
}

.clear-popup {
    color: #fff;
    background: red;
    width: 15px;
    height: 15px;
    position: absolute;
    top: -4px;
    right: -5px;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 600;
    cursor: pointer;
}

.popup-container {
    display: block;
    font-size: 14px;
    font-weight: 600;
    padding: 5px;
    color: #fff;
    text-align: left;
}


.open-popup1 {
    width: 230px;
    // min-height: 100px;
    height: auto;
    background-color: #3b3e66;
    position: absolute;
    bottom: 2px;
    right: 69px;
    border-radius: 4px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
}

.open-popup1:before {
    content: "";
    position: absolute;
    top: 51%;
    left: 100%;
    border: 8px solid #3b3e66;
    /* border-left-color: transparent; */
    border-right-color: transparent;
    border-bottom-color: transparent;
    border-top-color: transparent;
}

.outer {
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 1;
    // background-color: #00000042;
}

.company-slide-drawer {
    width: 30%;
    height: 40%;
    background-color: #001f3f;
    position: absolute;
    top: 102px;
    right: 0px;
}

.company-slide-drawer-close {
    width: -30%;
    height: 40%;
    background-color: #001f3f;
    position: absolute;
    top: 102px;
    right: 0px;
}


.company-slide-drawer {
    height: 100%;
    background-color: #fff;
    top: 74px;
    right: 0;
    position: fixed;
    /* Stay in place */
    z-index: 10;
    /* Stay on top */
    overflow-x: hidden;
    /* Disable horizontal scroll */
    transition: .8s;
    width: 80%;
}

.company-slide-drawer-close {
    height: 100%;
    background-color: #fff;
    top: 74px;
    right: -80%;
    position: fixed;
    /* Stay in place */
    z-index: 10;
    /* Stay on top */
    overflow-x: hidden;
    /* Disable horizontal scroll */
    transition: .8s;
    width: 80%;
}

.offcanvas-filter .company-slide-drawer {
    height: calc(100% - 74px);
    width: 50%;
}

.offcanvas-filter .close-btn-slider {
    position: relative;
    top: 0;
    right: 0;
    width: auto;
    height: auto;
    padding: 0;
}

.offcanvas-filter .hamburger-inner,
.offcanvas-filter .hamburger-inner::before,
.offcanvas-filter .hamburger-inner::after {
    width: 20px;
    height: 2px;
}

.offcanvas-filter .card {
    box-shadow: none;
}

.offcanvas-filter form.form-customise {
    padding: 0 15px 85px;
}
.offcanvas-filter .tabs-animated > .nav-tabs .nav-item .nav-link::before {
    transform: scale(1);
    background: #efefef;
}
.offcanvas-filter .tabs-animated > .nav-tabs .nav-item .nav-link.active::before, 
.offcanvas-filter .tabs-animated > .nav-tabs .nav-item .nav-link:hover::before {
    background-color: #3c44b1;
}
.offcanvas-filter .form-check-input:disabled {
    opacity: 1;
    background: #efefef;
}
.checkbox-label {
    border-bottom: 1px solid rgba(0, 0, 0, .15);
    padding: 15px 0;
}
.company-slide-drawer-close .canvas-footer {
    right: -50%;
}

.canvas-footer {
    position: fixed;
    bottom: 0;
    right: -50%;
    width: 50%;
    border-top: 1px solid #efefef;
    transition: 0.8s;
}

.company-slide-drawer .canvas-footer {
    right: 0%;
    transition: 0.8s;
}

.company-map-slide-drawer {
    height: 100%;
    background-color: #fff;
    top: 74px;
    right: 0;
    position: fixed;
    /* Stay in place */
    z-index: 10;
    /* Stay on top */
    overflow-x: hidden;
    /* Disable horizontal scroll */
    transition: .8s;
    width: 50%;
}

.company-map-slide-drawer-close {
    height: 100%;
    background-color: #fff;
    top: 74px;
    right: -80%;
    position: fixed;
    /* Stay in place */
    z-index: 10;
    /* Stay on top */
    overflow-x: hidden;
    /* Disable horizontal scroll */
    transition: .8s;
    width: 80%;
}




.class-btn {
    width: 40px;
    height: 40px;
    border-radius: 20px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    color: #000;
    background: #FFFFFF;
    position: fixed;
    top: 84px;
    margin-left: -20px;
    z-index: 2;
    display: flex;
    justify-content: center;
    align-items: center;

}

.company-slide-drawer-close .class-btn {
    margin-left: 0px;

}

.slider-bg {
    width: 100%;
    background: #2525252c;
    height: calc(100vh - 75px);
    top: 74px;
    right: 0;
    position: fixed;
    /* Stay in place */
    z-index: 10;
    /* Stay on top */
    overflow-x: hidden;
    /* Disable horizontal scroll */
}

.slider-bg-close {
    background: #2525252c;
    height: calc(100vh - 75px);
    top: 74px;
    right: -100%;
    position: fixed;
    /* Stay in place */
    z-index: 10;
    /* Stay on top */
    overflow-x: hidden;
    /* Disable horizontal scroll */

}

.slider-map-bg {
    width: 50%;
    background: #2525252c;
    height: calc(100vh - 75px);
    top: 74px;
    right: 0;
    position: fixed;
    /* Stay in place */
    z-index: 10;
    /* Stay on top */
    overflow-x: hidden;
    /* Disable horizontal scroll */
}

.slider-map-bg-close {
    background: #2525252c;
    height: calc(100vh - 75px);
    top: 74px;
    right: -50%;
    position: fixed;
    /* Stay in place */
    z-index: 10;
    /* Stay on top */
    overflow-x: hidden;
    /* Disable horizontal scroll */

}

.close-btn {
    border-radius: 30px;
    padding: 12px;
    width: 100%;
    height: 100%;
}

.close-btn-slider {
    top: 90px;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 40px;
    position: fixed;
    z-index: 1000;
    margin-left: -20px;
}

.company-map-slide-drawer-close .close-btn-slider {
    top: 90px;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 40px;
    position: fixed;
    z-index: 11;
    margin-left: 0px !important;
}

.company-slide-drawer-close .close-btn-slider {
    top: 90px;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 40px;
    position: fixed;
    z-index: 11;
    margin-left: 0px !important;
}

.offcanvas-filter .company-slide-drawer-close .close-btn-slider {
    display: none;
}

.data-title {
    cursor: pointer;
    color: red !important;
}

.data-title[data-title]:hover:after {
    opacity: 1;
    transition: all 0.1s ease 0.5s;
    visibility: visible;
}

.data-title[data-title]:after {
    content: attr(data-title);
    position: absolute;
    left: 5%;
    top: 20px;
    padding: 4px 4px 4px 8px;
    display: block;
    margin-left: 10px;
    color: #222;
    white-space: pre;
    -moz-border-radius: 5px;
    -webkit-border-radius: 5px;
    border-radius: 5px;
    -moz-box-shadow: 0px 0px 4px #222;
    -webkit-box-shadow: 0px 0px 4px #222;
    box-shadow: 0px 0px 4px #222;
    background: #001f3f;
    color: #fff;
    opacity: 0;
    z-index: 99999;
    visibility: hidden;
}

.data-title[data-title] {
    position: relative;
}

.upload-select {
    width: auto;
    height: auto;
    border: 1px solid #001f3f;
    color: #001f3f;
    padding: 5px 8px;
    border-radius: 4px;
    display: inline-block;
    margin: 3px 2px 0;
}

.upload-select-remove {
    width: auto;
    height: auto;
    background-color: #001f3f;
    color: #fff;
    border-radius: 4px 0 0 4px;
    padding: 5px 8px;
    display: inline-block;
}

.select-icon {
    width: auto;
    height: auto;
    background-color: red;
    color: #fff;
    border-radius: 0 4px 4px 0;
    padding: 5px 3px;
    margin-right: 5px;
    display: inline-block;
}

.upload-content {
    width: 100%;
    height: 20%;
    margin: 2px auto;
}

.dropzone-file {
    width: 100%;
    height: 100px;
    border: 2px dashed #e9e9e9;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    font-weight: 600;
}

.uploaded-files {
    background-color: #001f3f;
    padding: 5px;
    display: inline-block;
    color: #fff;
    border-radius: 2px 0 0 2px;
}

.upload-content span {
    padding: 5px;
    margin-right: 10px;
    background-color: red;
    display: inline-block;
    color: #fff;
    border-radius: 0 2px 2px 0;
}

.file-type {
    margin-bottom: .1rem;
    font-size: 13px;
    font-weight: 600;
    color: #0c1159;
    margin-top: 10px;
}

.add-stretch-form {
    width: 100%;
    height: auto;
    border-radius: 4px;
    position: absolute;
    z-index: 100;
    background-color: #fff;
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
    padding: 15px 0;
}

.leaflet-control-attribution.leaflet-control {
    display: none;
}

.count-my-project {
    width: auto;
    height: auto;
    display: inline-block;
    background-color: red;
    padding: 0 6px;
    border-radius: 2px;
    text-align: center;
    color: #fff;
    font-weight: 500;
    position: relative;
    top: 0;
    left: 70px;
    transition: all .35ms ease-in-out;

}

.offcanva .accordion-item {
    background-color: white;
}

.offcanva .accordion-button[aria-expanded="true"],
.offcanva .accordion-body {
    color: inherit;
    background-color: #ffffff;
    padding: 12px;
}

.offcanva .accordion-body {
    padding: 0;
}

.offcanva .accordion-button {
    background-color: #ffffff;
    color: inherit;
    font-size: 1rem;
    padding: 12px;
    font-weight: 600;
    font-size: 15px;
}

.offcanva .hamburger-box {
    width: 30px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    text-align: center;
}

.accordion-body .input-group-prepend label+.rdt .rdtPicker {
    right: 0 !important;
}

@media (max-width: 1440px) and (min-width: 1200px) {
    .count-my-project {
        width: auto;
        height: auto;
        display: inline-block;
        background-color: red;
        padding: 0 6px;
        border-radius: 2px;
        text-align: center;
        color: #fff;
        font-weight: 500;
        position: relative;
        top: 0;
        left: 42px;
        transition: all .35ms ease-in-out;
    }
}

.float_add {
    width: 180px;
    height: 50px;
    background-color: #3c44b1;
    color: #fff;
    font-size: 15px;
    font-weight: 500;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    bottom: 10vh;
    right: 50px;
    box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
    transition: all 0.5s ease-in-out;
    cursor: pointer;
}

.qry-add-slider {
    height: auto;
    width: 35%;
    background: #fff;
    position: absolute;
    top: 80px;
    padding: 10px;
    right: -100%;
    z-index: 99999;
    transition: all .5s ease-in-out;
    box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;

}

.qry-add-slider.active {
    right: 5px;
}

// .qry-add-slider-close{
//     height: auto;
//     width: -400px;
//     background: #fff;
//     position: absolute;
//     top: 80px;
//     padding: 10px;
//     right: -100%;
//     z-index: 10000;
//     transition: all .5s ease-in-out;
//     box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;

// }
.count-box {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 5px;
    height: auto;
    background: red;
    color: #fff;
    // padding: 1px 4px;
    min-width: 20px;
    text-align: center;
}

.count-box[data-count]::after {
    content: attr(data-count);
    display: block;
    position: absolute;
    font-size: 0.7rem;
    background: var(--color-3);
    color: var(--color-2);
    font-weight: bold;
    border-radius: 100px;
    padding: 0 0.25em;
    right: 5%;
    bottom: 5%;
}

input:not([type="file" i], [type="image" i], [type="checkbox" i], [type="radio" i], [type="text" i]) {}

.qry-add-slider-bg {
    width: 100%;
    height: 100vh;
    background-color: #00000021;
    position: absolute;
    top: 0;
    transition: all .5s ease-in-out;
    right: 0;
    z-index: 100;
}

.qry-add-slider-close-bg {
    width: 100%;
    height: 100vh;
    position: absolute;
    top: 0;
    transition: all .5s ease-in-out;
    right: -100%;
    z-index: 100;

}

.saved_success {
    width: 200px;
    height: 200px;
    color: green;
    background: #fff;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 20vh;
    right: 40%;
    left: 40%;
    z-index: 10;
    transition: all .5s ease-in-out;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;

}

// .saved_success::before{
//     content: "";
//     background-image: conic-gradient(
//         green 20deg,
//         transparent 120deg
//     );
//    width: 150%;
//    height: 20px;
//    position: absolute;
//    animation: rotate 2s linear infinite;
// }
// .saved_success::after {
//     content: "Project Added To My Project";
//     width: 100%;
//     height: 100%;
//     background: #fff;
//     position: absolute;
//     display: flex;
//     align-items: center;
//     justify-content: center;
// }
// @keyframes rotate {
//     0%{
//         transform: rotate(0deg);
//     }
//     100%{
//         transform: rotate(-360deg);
//     }
// }

.remove_success {
    width: 20%;
    height: 80px;
    color: red;
    background: #fff;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 20vh;
    right: 40%;
    left: 40%;
    z-index: 10;
    transition: all .5s ease-in-out;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.save_bg {
    width: 100%;
    height: 100vh;
    background: #00000033;
    position: fixed;
    top: 0;
    left: 0;
}

.group-list-check:enabled:checked {
    background-color: #3c44b1 !important;
    border-color: #3c44b1 !important;
    outline: none;
    box-shadow: none;
}

.group-list-check:focus {
    outline: none;
    box-shadow: none;
    border-color: #00000040 !important;
}

.project-feature {
    position: absolute;
    top: 30px;
    right: 15px;
    background-color: #fff;
    width: auto;
    height: auto;
    z-index: 1;
    border-radius: 4px;
    text-align: left;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    border: 1px solid #07091926;
    padding: 8px 0;
}

.project-feature li {
    padding: 10px 20px;
    color: #7a7b97;
    font-size: 15px;
    cursor: pointer;
}

.project-feature li:hover {
    background-color: #f4f5fd;
    color: #070919;

}

.project-feature-map {
    position: absolute;
    right: 15px;
    background-color: #fff;
    width: auto;
    height: auto;
    z-index: 1;
    border-radius: 4px;
    text-align: left;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    border: 1px solid #07091926;
    padding: 8px 0;
    z-index: 9999;
}

.project-feature-map li {
    padding: 10px 20px;
    color: #7a7b97;
    font-size: 15px;
    cursor: pointer;
}

.project-feature-map li:hover {
    background-color: #f4f5fd;
    color: #070919;

}

.swal-confirm.swal-text {
    text-align: center !important;
}

.swal-footer {
    text-align: center !important;
}

.publish-card {
    font-size: 13px;
    font-weight: 500;
    padding: 0 8px;
    height: 30px;
}

.update-log {
    flex-wrap: wrap;
}

@media (min-width : 500px) {
    .update-log {
        display: flex;
    }

}

.publish-loader {
    width: 100%;
    height: 100vh;
    position: absolute;
    top: 0;
    left: 0;
    background: #00000056;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 10000;
}

.file-box {
    width: 200px;
    height: 200px;
    background: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 5px solid #0c1159;

}

.file-box img {
    width: 100%;
}



.expand_table {
    visibility: collapse;
    transition: all 1s ease-in;
    opacity: 0;
}

.expand_table.active {
    visibility: visible;
    opacity: 1;
}

.filter_open {
    min-width: 310px;
    background: white;
    min-height: 200px;
    position: absolute;
    right: -100%;
    top: 75px;
    z-index: 999;
    border: 1px solid #dedede;
    transition: all .5s ease-in-out;
    opacity: 0;
}

.filter_open.active {
    right: 0;
    transition: all .5s ease-in-out;
    opacity: 1;
}

.legend-list {
    padding: 5px;
    align-items: center;
    display: flex;
    justify-content: space-between;
}

.doc {
    visibility: hidden;
    position: absolute;
    z-index: 10;
    right: 8px;
    background: white;
    transition: all .5s ease-in-out;
    width: 100%;
    // min-height: 300px;
    opacity: 0;
    border: 1px solid #dedede;
    bottom: 0px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.doc.doc_open {
    visibility: visible;
    opacity: 1;
    width: 100%;
    transition: all .5s ease-in-out;
}

.dashboard-sm .status-left-content {
    position: absolute;
    top: 11px;
    right: 0;
}

.dashboard-sm .status-left-content svg {
    font-size: 20px;
}

.dashboard-sm .card-list-box {
    border-radius: 4px;
    margin-bottom: 0;
    padding: 15px 10px !important;
}

.dashboard-sm.row,
.dashboard-sm .row {
    margin-left: -10px;
    margin-right: -10px;
}

@media (min-width: 768px) {

    .dashboard-sm.row .col-md-3,
    .dashboard-sm.row .col-md-6,
    .dashboard-sm.row .col-md-4,
    .dashboard-sm.row .col-md-12 {
        padding-left: 10px;
        padding-right: 10px;
    }
}

.info-card label {
    color: #0c1159;
    font-weight: 600;
}

.info-card .fw-600.card-header {
    font-weight: 600;
}

.status-table td:hover {
    cursor: pointer;
    background-color: rgb(244, 245, 253);
}

.status-table.table tbody td {
    padding: 10px;
    font-size: 14px;
    font-weight: 600;
    line-height: 1.2;
    color: #3b3e66;
    height: 70px;
}

.status-table p {
    margin: 0;
    font-size: 16px;
    color: #070919;
}

.status-table span {
    margin-top: 8px;
    display: block;
    color: rgb(59 62 102 / 90%) !important;
}

.status-table small {
    font-weight: 700;
}

.polar-chart .highcharts-figure,
.polar-chart .highcharts-data-table table {
    min-width: 320px;
    max-width: 800px;
    margin: 1em auto;
}

.polar-chart .highcharts-data-table table {
    font-family: Verdana, sans-serif;
    border-collapse: collapse;
    border: 1px solid #ebebeb;
    margin: 10px auto;
    text-align: center;
    width: 100%;
    max-width: 500px;
}

.polar-chart .highcharts-data-table caption {
    padding: 1em 0;
    font-size: 1.2em;
    color: #555;
}

.polar-chart .highcharts-data-table th {
    font-weight: 600;
    padding: 0.5em;
}

.polar-chart .highcharts-data-table td,
.polar-chart .highcharts-data-table th,
.polar-chart .highcharts-data-table caption {
    padding: 0.5em;
}

.polar-chart .highcharts-data-table thead tr,
.polar-chart .highcharts-data-table tr:nth-child(even) {
    background: #f8f8f8;
}

.polar-chart .highcharts-data-table tr:hover {
    background: #f1f7ff;
}

.ld-label {
    width: 200px;
    display: inline-block;
}

.ld-url-input {
    width: 500px;
}

.ld-time-input {
    width: 40px;
}

.polar-chart .highcharts-description {
    margin: 0.3rem 10px;
}
.btn:not(:disabled):hover {
    cursor: pointer;
}
.btn-eye, .btn-slash {
    display: none;
}
.open-eye .btn-eye {
    display: block;
}
.close-eye .btn-slash {
    display: block;
}
.log-table .table thead th {
    background-color: #f7f7f7;
}
.log-table > td {
  background-color: #ffffff !important;
}
.log-table .table thead th {
    border-bottom: 2px solid rgb(219.9375, 222, 240.5625) !important;
}
tr.close-eye {
    --bs-table-accent-bg: #00000013 !important;
}
.btngroup.btn {
    position: relative;
    padding-left: 40px;
    white-space: nowrap;
}
.trust-action .btn {
    display: flex;
    padding: 4px 8px;
}
.filter-input-group.project-search.input-group {
    width: 70%;
    margin: auto;
}

.filter-input-group.project-search.input-group>div {
    width: calc(100% - 100px);
}
.filter-input-group.project-search.input-group .form-control-sm {
    height: calc(1.5em + 1.68rem + 2px);
    padding: 0.84rem 1.8rem;
    font-size: 0.95rem;
    line-height: 1.5;
    border-radius: 0.29rem;
    border-top-left-radius: 0.29rem;
    border-bottom-left-radius: 0.29rem;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}
.filter-input-group.project-search.input-group .form-control:focus {
    color: #3b3e66;
    background-color: #fff;
    border-color: rgb(146.335443038, 151.246835443, 218.164556962);
    outline: 0;
    box-shadow: none
}
.filter-input-group.project-search.input-group .btn {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-top-right-radius: 0.29rem;
    border-bottom-right-radius: 0.29rem;
}
.trust-page-title {
    z-index: 9;
    position: relative;
    margin: 0;
    margin-bottom: 10px;
    padding-left: 15px;
    padding-right: 15px;
    background-color: transparent;
}
.search-clear {
    position: absolute;
    right: 15px;
    top: 14px;
    cursor: pointer;
    width: 15px;
    height: 22px;
    text-align: center;
}
.company-custom-table .header .sno {
    border: none;
    height: auto;
    justify-content: center;
  }
  .company-custom-table .sno {
    min-width: 50px;
    width: auto;
    flex: none;
    border-right: 1px solid #CECECE;
    height: 30px;
    display: flex;
    align-items: center;
    padding-left: 10px;
    padding-right: 10px;
    justify-content: center;
  }
  .company-custom-table .fin-col {
    min-width: 100px;
    width: auto;
    flex: none;
    text-align: center;
  }
  .project-feature li {
    white-space: nowrap;
  }
  .trust-list {
    margin-bottom: 1rem;
    border-radius: 4px;
  }
  .trust-list .label-card {
    font-size: 14px;
  }
@media (min-width: 1600px) {
    .modal-xlx {
        width: 1450px;
    }
}

@media (max-width: 1600px) {
    .modal-xlx {
        width: 100%;
    }
}