@import url('https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/css/bootstrap.min.css');
@import url('https://fonts.googleapis.com/icon?family=Material+Icons');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;600;700&display=swap');

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: Poppins;
}

li {
    list-style: none;
}

a {
    text-decoration: none;
}
/* .center {
    margin: 0;
    position: relative;
    top: 50%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
} */

.body {
    background-color: #1D3348;
    font-family: "Poppins", sans-serif;
    padding: 40px 8vw;
    display: flex;
    flex-direction: column;
    color: white;
    justify-content: center;
    /* min-height: 100vh; */
}

.flex-row-wrap {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.flex-col {
    display: flex;
    flex-direction: column;
    justify-content: left;
    align-items: flex-start;
}

.flex-col-center {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.tab {
    overflow: hidden;
    background-color: transparent;
}

.tab button {
    background-color: inherit;
    float: left;
    border: none;
    outline: none;
    cursor: pointer;
    padding: 14px 16px;
    transition: 0.3s;
    font-size: 17px;
    color: white;
}

.tab button:hover {
    background-color: transparent;
}

.tab button.active {
    background-color: transparent;
    border-bottom: 2px solid #61C7C1;
    color: #61C7C1;
}

.tabcontent {
    display: none;
    padding: 6px 12px;
    color: white;
    border-top: none;
    background-color: transparent;
}


/* Section L */