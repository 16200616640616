
@media (min-width: 1200px) and (max-width: 1440px) {

    $header-height: 60px;
    $sidebar-width: 220px;

    .alert, .alert-dismissible .close {
        padding: 0.45rem 1.25rem;        
    }
    .modal{
        .modal-dialog.modal-xl.modal-dialog-centered{
            max-width: 920px !important;

            .login-register-popup-modal {

                .nav.nav-line .nav-item .nav-link{
                    font-size: 0.83rem;
                    padding: 0 1rem 0.62rem;
                }
        
                .sign-in-container {
                    .h1-title{
                        font-size: 0.9rem;
                    }
                    .p-subtitle{
                        font-size: 0.83rem;
                    }
                }
        
                .btn.btn-lg{
                    padding: 0.41rem 1.3rem !important;
                    font-size: 0.83rem;
                }
        
                .forgot-pwd-content{
                    font-size: 0.805rem;
                }
        
                .form-group .form-control{
                    height: 35px;
                    border-radius: 2px;
                    padding: 0.25rem 0.9rem;
                    font-size: 0.82rem;
                }
                .ip-address-subtitle{
                    font-size: 0.83rem;
                }
        
                .register-form-container {
                    .form-group{
                        margin-bottom: 0.6rem;
                    }
                }

                .left-side-content{
                    h1{
                        font-size: 1.3rem;
                    }
                }
            }
        }
    }

    .app-header-fixed {
        .app-header {
            height: $header-height;
            left: $sidebar-width;
        }
        .app-content {
            padding-top: $header-height;
            padding-left: $sidebar-width;
        }        
    }
      
    

    .app-sidebar {
        width: $sidebar-width;
        &--header {
            min-height: $header-height;
            height: $header-height;
        }
    }

    .page-drawer-content, .page-drawer-overlay {
        top: $header-height;
    }

    .nav-logo a {
        font-size: 1.2rem;
        
        i {
            width: 24px;
            height: 22px;

            img{
                width: 20px;
            }
        }
    }
    
    .login-user-name-content {
        .dropdown-toggle-btn {
            font-size: 0.82rem;

            .avatar-icon-wrapper {
                .avatar-icon {
                    width: 34px;
                    height: 34px;
                    line-height: 34px;
                }
            }        
        }    
        .dropdown-menu{
            .dropdown-item {
                font-size: 0.82rem;
                padding: 0.40rem 1.2rem;
            }
        }    
    }

    .sidebar-navigation {
        ul li {
            
            &.submenu-open ul {
                padding: 0.5rem 0 0 0;
            }
            a {
                padding: 0 1.33333rem;
                font-size: 0.82364rem;
                height: 38px;

                .sidebar-icon {
                    width: 22px;
                    height: 20px;
                    font-size: 1.44375rem;
                }
            }
        }
    }

    .app-sidebar--footer {
        height: 34px;

        .footer-logout-btn-tooltip {
            font-size: 0.80rem !important;
        }
    }

    .app-sidebar-collapsed {
        .app-sidebar--header .nav-logo i {
            left: 17px;
        }
        .sidebar-navigation {
            ul li {
                a {
                    padding: 0 1.33333rem 0 2rem;
                }    
            }
        }
        .app-content {
            padding-left: $sidebar-width-collapsed;
        } 
        
        .app-sidebar {        
            &:hover:not(.app-sidebar--mini) {
                width: $sidebar-width;

                .app-sidebar--footer {
                    height: 34px;
                }
            }
        }
    }

    /***** Page Title *****/
    .app-page-title {
        &--heading {
            h1 { font-size: 0.82rem; }    
        }        

        .filter-input-group {
            font-size: 0.82rem;
            max-height: 30px;
    
            .form-control-sm {
                height: 28px;
                font-size: 0.70rem;
                padding: 0.25rem 0.4rem;
            }
    
            button {
                font-size: 0.75rem;
                padding: 0.25rem 0.75rem;
            }
        }
        .add-update-btn-page-title {
            /***padding: 0.25rem 0.7rem;****/
            padding: 0.1755rem 0.65rem;
            font-size: 0.65rem;
            border-radius: 0.1rem;

            .svg-inline--fa.fa-plus {
                font-size: 0.82rem;
            }
        }
    }

    /********* Dashboard-Page ********/
    .dboard-heading {
        padding: 0.35rem 1.25rem;

        h5 {
            font-size: 0.88rem;
        }
        
        button {
            padding: 0.15rem 1.1rem;
        }
    }

    .dboard-status-card-box {
        min-height: 125px;
        max-height: 125px;
        overflow: hidden;
        text-overflow: ellipsis;
        font-size: 0.72rem;
        padding: 0.82rem !important;

        .status-left-content{
            padding-right: 0.5rem !important;

            .svg-inline--fa{
                width: 0.825em !important;
                font-size: 2rem !important;
                &.fa-question{
                    font-size: 1.6rem !important;
                }
            }		
        }

        .status-right-content {
            span.text-black-50 {
                font-size: 0.80rem !important;
            }
        }
        h5 {
            font-size: 0.80rem !important;
		    padding-bottom: 0.6rem !important;
        }

        .cost-amt-fsize {
            font-size: 0.83rem;
        } 
        
        .progress {
            height: 0.8rem;
            font-size: 0.55rem;
            border-radius: 0.1rem;

            .progress-bar:last-child {
                border-top-right-radius: 0.1rem;
                border-bottom-right-radius: 0.1rem;
            }
        }

        &.operation-maintenance-stage {
            .amt-project-val-container {
                padding-bottom: 0.8rem !important;
            }
        }
    }

    .road-pie-chart-card-container, .metro-pie-chart-project-mode-container,
     .metro-pie-chart-project-status-container, .ws-pie-chart-container {
        min-height: 266px;
        max-height: 266px;
    }

    /********* Dashboard-Page - End ********/

    /********** User Tab Content - start **********/
    .tabs-animated > .nav-tabs .nav-item .nav-link {
        font-size: 0.72rem;
        padding: 0.36667rem 1rem;

        &::before{
            bottom: 0px; 
            height: 2px;
        }        
    }
    /********** User Tab Content - End **********/

    /********* RC- Select Options - Start ********/
    .rc-select {
        line-height: 22px;
        font-size: 0.70rem;
        min-height: 28px;

        .rc-select-selection {
            &--single {
                height: 28px;
                line-height: 28px;

                .rc-select-selection__rendered {
                    height: 28px;
                    margin-left: 8px;
                    margin-right: 26px;
                    line-height: 28px;
                }
                .rc-select-arrow {
                    height: 26px;
                }
                .rc-select-selection__clear {
                    top: 50%;
                    line-height: 1;
                    transform: translateY(-50%);
                }
            }
            &--multiple {
                min-height: 19px;

                .rc-select-selection__rendered .rc-select-selection__choice {
                    margin-top: 4px;
                    line-height: 18px;
                    font-size: 0.60rem;
                }

                .rc-select-selection__clear {
                    top: 50%;
                    right: 5px;
                    line-height: 1;
                    transform: translateY(-50%);
                }
            }            
        }
        .rc-select-selection__choice {
            border-radius: 2px;
        }

        /***.rc-select-selection__clear {
            line-height: 28px;
            right: 8px;
        }    ***/    
    }
    .rc-select:not(.rc-select-no-arrow) .rc-select-selection--multiple .rc-select-selection__clear, .rc-select-loading .rc-select-selection--multiple .rc-select-selection__clear {
        right: 8px;
    }
    li.rc-select-dropdown-menu-item {
        font-size: 0.70rem;
    }
    .rc-select-dropdown-menu-item-group-title {
        font-size: 0.70rem !important;
    }
    /********* RC- Select Options - End ********/

    /********** React AutoComplete - Start **********/
    .react-auto-complete--menu {
        font-size: 0.70rem;
    }
    /********** React AutoComplete - End **********/

    /******** Table List - Start *******/
    .table.table-custom {
        thead {
            th {
                font-size: 0.65rem;
                font-weight: 600;
                padding: 0.25rem 0.75rem;
            }
        }
        tbody {
            th {
                font-size: 0.70rem;
                padding: 0.25rem 0.55rem;
            }
            td {
                font-size: 0.70rem;
                /***padding: 0.25rem 0.75rem;***/
                padding: 0.25rem 0.55rem;

                .action-col-cell {
                    div {
                        padding: 0 0.15rem;

                        .btn.btn-sm {
                            /***padding: 0.25rem 0.65rem;***/
                            padding: 0.20rem 0.60rem;
                            font-size: 0.70125rem;
                            border-radius: 0.1rem;
                            border-width: 1px !important;
                        }
                    }
                }
            }
        }
    }
    .pagination-container {
        .rc-pagination-total-text {
            font-size: 0.70rem;
        }
        .rc-pagination {
            li {
                font-size: 0.70rem;
                height: 28px;
                line-height: 28px;
                min-width: 30px;
                border-radius: 0.1rem;
            }
        }

        .rc-select {
            line-height: 28px;
            font-size: 0.70rem;
        }
        .rc-pagination-options-size-changer {
            width: 80px;
        }

        .rc-select-selection--single {
            height: 28px;
            line-height: 28px;

            .rc-select-selection__rendered {
                height: 28px;
                line-height: 28px;
            }

            .rc-select-arrow {
                height: 26px;
            }
        }
    }
    /******** Table List - End *******/

    /****** Page Drawer Slider - Start ****/
    .page-drawer-header-container {
        padding-top: 0.35rem !important;
        padding-bottom: 0.35rem !important;
        h6 {
            font-size: 0.82rem;
        }
    }

    .page-drawer-content .close-page-drawer-btn {
        left: -14px;
        width: 30px;
        height: 30px;
        top: 8px;
        
        .hamburger-box {
            width: 18px;
            height: 14px;
        
            .hamburger-inner, .hamburger-inner::before, .hamburger-inner::after {
                width: 18px;
                height: 2px;
            }
        }
    }	
        
    .form-customise {

        .input-group {
            .input-group-text {
                padding: 0.44rem 0.7rem;

                .svg-inline--fa {
                    font-size: 0.76rem;
                }
            }
        } 

        .form-group {
            label {
                font-size: 0.70rem;
                margin-bottom: 0.1rem;
            }
            .form-control-sm {
                height: 28px;
                padding: 0.25rem 0.5rem;
                font-size: 0.70rem;
                line-height: 1.2;
            }
            .form-control.is-invalid, .was-validated .form-control:invalid {
                background-position: 98% calc(0.205em + 0.155rem) !important;
            }

            .input-group.subscription-period-group .input-group-text {
                padding: 0.305rem 0.8rem;
                font-size: 0.70rem;
            }
          
        }
        .custom-control {
            .custom-control-label {
                font-size: 0.70rem !important;

                &::before, &::after {
                    width: 1.1rem;
                    height: 1.1rem;
                }
            }
        }
        .form-reset-cancel-btn, .form-submit-btn {
            /***padding: 0.41rem 1.3rem !important;***/
            padding: 0.28rem 1.3rem !important;
            font-size: 0.82rem;
            border-radius: 0.1rem;
            border-width: 1px !important;
        }
        
        .card-header-and-or-cond {
            .custom-radio.custom-control {
                .custom-control-label {
                    padding-top: 3px;
                }
            }
        }

        .custom-file {
            height: calc(1em + 1rem + 2px);

            .custom-file-input {
                height: calc(1em + 1rem + 2px);
            }            
            .custom-file-label {
                height: calc(1em + 1rem + 2px);
                line-height: 1;
                border-radius: 0.1rem!important;

                &::after {
                    height: calc(1em + 1rem);
                    line-height: 1;
                }
            }
        }
        .col-form-label {
            padding-top: calc(0.43rem + 1px);
            padding-bottom: calc(0.43rem + 1px);
            line-height: 1;
        }
    }
    .custom-control {
        .custom-control-label {
            font-size: 0.70rem !important;

            &::before, &::after {
                width: 1.1rem;
                height: 1.1rem;
            }
            
            &::after {
                top: 0.07rem;
                left: -2.159rem;
            }

        }

        &.custom-checkbox {
            .custom-control-label {
                &::before, &::after {
                    left: -1.6rem!important;
                }
            }
        }
    }
    .add-remove-btn-label {
        margin-bottom: 0.1rem;
    }
    .add-remove-inner-btn {
        /***padding: 0.17255rem 0.85rem !important;***/
        padding: 0.10255rem 0.65rem !important;
        border-radius: 0.1rem;
    }

    .add-member-rows {
        .add-member-col-name {
            padding-right: 0;
        }
    }

    /****** Page Drawer Slider - End ****/

    /******** Select2 - Start ********/
    select.form-control-sm ~ .select2-container--bootstrap4 .select2-selection--single {
        height: 28px !important;
        font-size: 0.70rem;

        .select2-selection__rendered {
            line-height: 1.7rem;
        }
        .select2-selection__placeholder {
            line-height: 1.7rem;
        }
    }
    .select2-container--bootstrap4 .select2-results > .select2-results__options {
        font-size: 0.70rem;
    }
    .select2-search--dropdown .select2-search__field {
        font-size: 0.70rem;
        min-height: 28px!important;
    }

    select.form-control-sm ~ .select2-container--bootstrap4 .select2-selection--multiple {
        font-size: 0.70rem;
        min-height: 28px !important;
        position: relative;

        .select2-search--inline .select2-search__field {
            margin-top: 0;
            position: absolute;
            top: 50%;
            font-size: 0.70rem;
            transform: translateY(-50%);
        }
    }
    /******** Select2 - End ********/

    /****** DateTime Range Picker *****/
    .rdtPicker {
        th.rdtNext, th.rdtPrev {
            font-size: 16px !important;
        }
        th.rdtSwitch {
            font-size: 0.70rem !important;
        }
        .dow {
            font-size: 0.70rem !important;
        }

        td.rdtOld, td.rdtNew {
            font-size: 0.70rem !important;
        }

        tbody  td, tbody th {
            font-size: 0.70rem !important;
        }

        td.rdtMonth, td.rdtYear {
            font-size: 0.70rem !important;
        }
    } 

    /******* SweetAlert Styles - Start *****/
    .swal-modal{
        width: 300px!important;
        border-radius: 0.2rem;

        .swal-icon:not(.swal-icon--success) {
            width: 50px;
            height: 50px;

            &:first-child {
                margin-top: 16px !important;
            }            
        }

        .swal-icon--error__x-mark {
            .swal-icon--error__line {
                height: 5px;
                width: 36px;
                top: 22px;

                &.swal-icon--error__line--left {
                    left: 7px;
                }

                &.swal-icon--error__line--right {
                    right: 7px;
                }
            }
        }

        .swal-icon--info:before {
            width: 5px;
            height: 30px;
            bottom: 5px;
        }
        .swal-icon--info:after {
            width: 7px;
            height: 7px;
            top: 4px;
        }

        .swal-icon--warning {
            .swal-icon--warning__body {
                width: 5px;
                height: 28px;
                top: 6px;
            }
        }

        .swal-title {
            font-size: 13px;
        }

        .swal-text {
            font-size: 12px;
        }

        .swal-footer {
            padding-top: 10px;
            margin-top: 10px;
        }
        .swal-button-container {
            margin: 2px 5px;

            .swal-button {
                font-size: 12px;
                font-weight: 400;
                padding: 6px 20px;
                border-radius: 0.1rem;
            }
        }
    }
    /******* SweetAlert Styles - End *****/

    /******* Road Project Page - Start ******/
    .filter-label-text { font-size: 0.70rem; }

    .project-list-card {
        .card-header {
            padding: 0.4rem 0.8rem;

            .project-name { font-size: 0.70rem !important; }
            .project-list-status { font-size: 0.70rem!important; }
        }

        .card-body {
            font-size: 0.70rem !important;
            padding: 0.3rem 0.8rem!important;
        }

        .card-footer {
            padding: 0.4rem 0.8rem;

            .col { font-size: 0.70rem;
                .btn-sm-icon {
                    font-size: 0.65rem;
                }
            }
        }
    }

    .project-view-details-card {
        .card-body {
            font-size: 0.70rem !important;
            padding: 0.3rem 0.8rem!important;

            .project-name { font-size: 0.82rem !important; }

            .project-view-details-card {
                .font-weight-light { font-size: 0.70rem !important; }
            }
        }
    }

    .add-update-project-page-card {
        #view_finance {
            .svg-inline--fa {
                font-size: 0.82rem !important;
            }
        }
    }

    /*** Query Search Page ***/
    .query-search-form-card {
        .card-header {
            padding: 0.75rem !important;
        }
        .card-sub-title {
            font-size: 0.80rem !important;
            padding: 0 !important;
        }
        // .card-body {
        //     padding: 0.75rem !important;
        // }

        .qry-param-selected-label {
            padding: 3px 5px;
            margin-left: 5px;
            margin-right: 5px;

            small {
                font-size: 62%;
            }

            .qry-label-btn span.btn.label-close-btn {
                padding: 2.5px 5px;
                line-height: 1;
            }
        } 
        
        .overall-cost {
            font-size: 0.70rem;
        }

        &.show-additional-columns-container {
            z-index: 9;
            transform: translate(97.60%);
            top: 110px;

            &.open {
                transform: translate(0);
            }
        }

        .show-additional-columns-container-body {
            &.card-body{
                padding: 0.75rem 0.85rem !important;
            }
            .checkbox-custom-qry-search {
                .custom-control-label {
                    padding-top: 3px;
                }
            }

            .show-additional-columns-inner {
                left: 0px;
                top:0px;
                min-width: 198px;
                font-size: 0.80rem;
            }
        }
    }
   
    /*** Query Search Page ***/

    /******* Road Project Page - End ******/

    /******* Add Finance - Start ******/
    .add-finance-page-title {
        .title-right-side-container {
            button {
                margin: 0 5px;
            }
        }
    }
    .finance-card-container {
        .card-body {
            padding: 0.75rem;
        }

        .finance-nav-tabs.nav.nav-tabs .nav-item .nav-link {
            font-size: 0.70rem;
            padding: 6px 14px;
        }

        .finance-data-tab-content {
            a.btn {
                padding: 0.15rem 1.1rem;
            }
            div i {
                font-size: 0.70rem!important;
            }
        }

        &.show-in-popup {
            .card-body {
                overflow-x: hidden;
                padding: 0.05rem 0.75rem !important;
            }            

            .finance-nav-tabs.nav.nav-tabs .nav-item .nav-link {
                font-size: 0.60rem;
                padding: 6px 10px;
            }

            .tab-content.finance-data-tab-content {
                padding-left: 1rem!important;
                padding-right: 1rem!important;
            }
        }
    }
    .no-data-found-card {
        h5 {
            font-size: 0.95rem;
        }
    }
    /******* Add Finance - End ******/

    /********* Grooup Admin - Manage Users *********/
    .general-text {
        font-size: 0.70rem;

        &.alert{
            padding: 0.35rem 1rem;            
        }
    }
    /********* Grooup Admin - Manage Users *********/
    
}
.card-body-style {
    padding: .6rem !important;
}
.form-group-style {
    margin-bottom: .4rem !important;
}
.water-form-group {
    margin-bottom: .8rem;
}

@media (min-width: 768px) and (max-width: 1200px) {
    .query-search-form-card {
        .show-additional-columns-container-body {
            .show-additional-columns-inner {
                left: 0px;
                top:0px;
                min-width: 198px;
                font-size: 0.80rem;
            }

        }
    }

}


@media (min-width: 768px) {

    .form-col {
        padding-right: 5px !important;
    }
    .form-col1 {
        padding-left: 5px !important;
    }
    .form-col2 {
        padding-right: 5px !important;
    }
    .form-col3 {
        padding-left: 5px !important;
    }
    

}
@media (max-width: 350px) {
    .padding-btn{
        padding: 3px !important;
    }
}
