a {
    text-decoration: none;
}
/* Scroll Bar */

 ::-webkit-scrollbar {
    width: 1px;
}

 ::-webkit-scrollbar-track {
    background: #1D3348;
}

 ::-webkit-scrollbar-thumb {
    background: #61C7C1;
}

.inputBox {
    border-radius: 5px;
    padding: 8px 20px;
    border: none;
    width: 100%;
    outline: none;
    background-color: #1D3348;
    color: white;
    border: 1px solid white;
}

.loginBtn {
    padding: 10px;
    width: 100%;
    background-color: #61C7C1;
    color: white;
    outline: none;
    border: none;
    border-radius: 5px;
}

 ::placeholder {
    color: rgb(201, 200, 200);
    opacity: 1;
}

.row>* {
    padding: 0px;
}

.checkboxes label {
    display: inline-block;
    padding-right: 10px;
    white-space: nowrap;
}

.checkboxes input {
    vertical-align: middle;
}

.checkboxes label span {
    vertical-align: middle;
}

.bg_image {
    display: block;
}

.whatisidip_video_container .modal-content { width: 70vw; }
.tButton {
    padding: 10px 50px;
    background-color: #61C7C1;
    border-radius: 10px;
    color: white;
}

.tabRightContent {
    height: 100%;
    justify-content: center;
    padding: 20px;
    gap: 10px;
}

/******** Team Content Style- Start ********/
.flip-card {
    background-color: transparent;
    min-height: 450px;
    padding: 10px;
}

.flip-card-inner {
    position: relative;
    /* width: 100%; */
    /* height: 100%; */
    /* text-align: center; */
    transition: transform 0.6s;
    transform-style: preserve-3d;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
}

.flip-card:hover .flip-card-inner {
    transform: rotateY(180deg);
}

.flip-card-front,
.flip-card-back {
    position: absolute;
    width: 100%;
    /* height: 100%; */
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
}

.flip-card-back {
    color: white;
    transform: rotateY(180deg);
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
}

.flip-card-back-inner {
    min-height: 450px;
    padding: 30px 20px;
    background: rgba(21, 27, 32, 0.45);
    backdrop-filter: blur(50px);
}

.flip-card-back-inner-title {
    font-weight: 600;
    font-size: 20px;
    margin-bottom: 10px;
}

.flip-card-back-inner-desc {
    font-weight: 300;
}
/******** Team Content Style- End ********/

@media (max-width:1000px) {
    .bg_image {
        display: none;
    }
}