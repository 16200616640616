.header {
    /*
    box-shadow: 0px 50px 10px -10px #828282;
    background-color: #1D3348;
    padding: 10px;
    z-index: 20;
    */
    box-shadow: 0px 10px 10px -10px #828282;
    background-color: #1D3348;
    padding: 10px;
    z-index: 997;
    position: fixed;
    width: 100%;
}

.navbar {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    padding: 40px 40px;
    margin: 0px 30px;
}

@media (max-width: 768px) { 
    .navbar {
      margin: 0px 5px;
    }
  }

.hamburger {
    display: none;
}

.bar {
    display: block;
    width: 25px;
    height: 3px;
    margin: 5px auto;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    background-color: white;
}

.nav-menu {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    z-index: 10;
    padding-left: 150px;
    background-color: #1D3348;
    color: white;
}

.nav-menu a { font-family: inherit; font-size: 18px; color: #fff; font-weight: 400; }

.nav-item {
    margin-left: 1rem;
}

.nav-links {
    font-size: 1.6rem;
    font-weight: 400;
    font-size: 18px;
    margin: 0px 10px;
    color: white
}

.nav-logo {
    font-size: 30px;
    font-weight: 800;
    color: #61C7C1;
}

@media only screen and (max-width: 768px) {

    .nav-menu,
    .nav-buttons {
        position: fixed;
        left: -100%;
        top: 5rem;
        flex-direction: column;
        width: 100%;
        border-radius: 10px;
        text-align: center;
        transition: 0.3s;
        box-shadow:
            0 10px 27px rgba(0, 0, 0, 0.05);
    }

    .nav-menu.active {
        left: 0;
        background-color: #1D3348;
        color: white;
        padding-left: 0;
        justify-content: start;
    }
    .nav-menu.active > li {
        margin: 0;
        width: 100%;
    }

    .nav-menu {
        border-top: 1px solid #fff;
        border-radius: 0;
    }

    .header {
        position: fixed;
        width: 100%;
        box-shadow: 0 5px 10px -5px #dbdbdb;
    }

    .theme-light .nav-menu {
        border-top: 1px solid #1D3348;
        border-radius: 0;
    }

    .nav-menu > li.nav-item > a.nav-link {
        font-family: inherit;
        font-size: 18px;
        color: #fff;
        font-weight: 400;
        padding: 20px 15px;
        display: inline-flex;
        width: 100%;
        justify-content: center;
        border-bottom: 1px solid #fff;
    }
    .theme-light .nav-menu > li.nav-item > a.nav-link {
        border-bottom: 1px solid #1D3348;
    }

    .nav-buttons.active {
        left: 0
    }

    .nav-item {
        /**margin: 2.5rem 0;**/
        margin: 1.25rem 0;
    }

    .hamburger {
        display: block;
        cursor: pointer;
    }


    .hamburger.active .bar:nth-child(2) {
        opacity: 0;
    }

    .hamburger.active .bar:nth-child(1) {
        transform: translateY(8px) rotate(45deg);
    }

    .hamburger.active .bar:nth-child(3) {
        transform: translateY(-8px) rotate(-45deg);
    }
    .mobile-theme-change-btn{
        position: absolute;
        top: 53%;
        right: 15%;
        transform: translate(-50%, -50%);
        color: #fff;
    }
}

.login-btn {
    background-color: #1D3348;
    color: #61C7C1;
    font-size: 14px;
    font-weight: 500;
    padding: 6px 20px;
    border: 1px solid #61C7C1;
    border-radius: 5px;
    cursor: pointer;
    margin-right: 10px;
}

.free-trail-btn {
    background-color: #61C7C1;
    color: #fff;
    font-size: 16px;
    font-weight: 600;
    line-height: 1.55;
    padding: 6px 20px;
    border-radius: 5px;
    cursor: pointer;
    border: none;
    outline: none
}